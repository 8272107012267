import React from "react"
import {
  button,
  enabled,
  disabledbtn,
  submitting,
  success,
  show,
  hide,
  checkmark,
  checkmark__check,
  checkmark__circle,
} from "./SubmitButton.module.scss"
import { useFormikContext } from "formik"

export const SubmitButton = ({ status }) => {
  
  const { errors } = useFormikContext()

  let buttonClass
  let textClass
  let disabled
  if (errors.message) {
    buttonClass = disabled
    textClass = show
    disabled = true
  } else {
    switch (status) {
      case "enabled":
        buttonClass = enabled
        textClass = show
        disabled = false
        break
      case "disabled":
        buttonClass = disabledbtn
        textClass = show
        disabled = true
        break
      case "submitting":
        buttonClass = submitting
        textClass = hide
        disabled = true
        break
      case "success":
        buttonClass = success
        textClass = hide
        disabled = true
        break
      default:
        buttonClass = disabled
        textClass = show
        disabled = true
        break
    }
  }

  return (
    <button className={`${button} ${buttonClass}`} disabled={disabled}>
      <span className={textClass}>Submit</span>
      {status === "success" && <Checkmark />}
    </button>
  )
}

const Checkmark = () => {
  return (
    <svg
      className={checkmark}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        className={checkmark__circle}
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className={checkmark__check}
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  )
}
