import React from "react"
import { useField } from "formik"
import { inputText } from "./ContactForm.module.scss"
import { ValidationMessage } from "./ValidationMessage"
import { FormItem } from "./FormItem"

export const TextInput = props => {
  const [field, meta] = useField(props)
  return (
    <FormItem name={props.name} label={props.label}>
      <input className={inputText} {...field} {...props} />
      <ValidationMessage meta={meta} />
    </FormItem>
  )
}
