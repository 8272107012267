import React from "react"
import { PageHero } from "../components/hero/PageHero"
import { Section } from "../layout/surfaces/Section"
import { Container } from "../layout/surfaces/Container"
import { ContactForm } from "../components/forms/contact/ContactForm"
import { Heading } from "../components/typogarphy/Heading"
import { Paragraph } from "../components/typogarphy/Paragraph"
import {
  content,
  message,
  info,
  contactInfo,
} from "../content/contact/ContactPage.module.scss"
const ContactPage = () => {
  return (
    <>
      <PageHero type="contact" />
      <Section>
        <Container>
          <div className={content}>
            <div className={message}>
              <Heading variant="h2">Contact Us</Heading>
              <Paragraph>
                Chat with us today if you'd like to discuss our services
              </Paragraph>
              <div className={info}>
                <div className={contactInfo}>
                  <Heading variant="h6">Email</Heading>
                  <Paragraph>sales@deyst.com.au</Paragraph>
                </div>
              </div>
              <Heading variant="h6">Office Hours</Heading>
              <ul>
                <li>Monday to Friday 8AM-5PM(AWST)</li>
                <li>Closed Public Holidays</li>
              </ul>
            </div>

            <ContactForm />
          </div>
        </Container>
      </Section>
    </>
  )
}

export default ContactPage
