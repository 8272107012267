import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { form, formItem } from "./ContactForm.module.scss"
import { TextArea } from "./TextArea"
import { TextInput } from "./TextInput"
import { SubmitButton } from "../../buttons/SubmitButton"

const sendMessage = async values => {
  const body = JSON.stringify(values)
  await fetch("/api/contact", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: body,
  })
}

const initialValues = {
  name: "",
  email: "",
  message: "",
}

const validationSchema = Yup.object({
  name: Yup.string()
    .max(200, "Sorry, this name is too long")
    .required("Please provide a name so we can get back to you"),
  email: Yup.string()
    .email("Please provide a valid email address")
    .required("Please provide a valid email address"),
  message: Yup.string()
    .max(1000, "Sorry, this message is too long. Please shorten the message")
    .required("Please provide a message so we know what your enquiry is about"),
})

export const ContactForm = () => {
  const [buttonStatus, setButtonStatus] = React.useState("disabled")

  const onReCaptchaExpired = () => {
    setButtonStatus("disabled")
  }

  const onReCaptchaSuccess = () => {
    setButtonStatus("enabled")
  }

  const timeout = () => {
    return new Promise(resolve => setTimeout(resolve, 2000))
  }

  const onFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setButtonStatus("submitting")
    try {
      sendMessage(values)
      await timeout
      setButtonStatus("success")
    } catch (err) {
      console.error(err)
      setButtonStatus("enabled")
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={form}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        <Form>
          <TextInput label="Name" name="name" type="text" />
          <TextInput label="Email Address" name="email" type="email" />
          <TextArea label="Message" name="message" rows="7" />
          <FormReCAPTCHA
            onReCaptchaSuccess={onReCaptchaSuccess}
            onReCaptchaExpired={onReCaptchaExpired}
          />
          <SubmitButton status={buttonStatus} />
        </Form>
      </Formik>
    </div>
  )
}

export const FormReCAPTCHA = ({ onReCaptchaSuccess, onReCaptchaExpired }) => {
  return (
    <div className={formItem}>
      <ReCAPTCHA
        sitekey="6LfTtlEaAAAAAFPnLIA3Z8RnivPiYvzSKj59-a-V"
        onChange={onReCaptchaSuccess}
        onExpired={onReCaptchaExpired}
      />
    </div>
  )
}
