import React from "react"
import { formItem, formLabel } from "./ContactForm.module.scss"

export const FormItem = props => {
  return (
    <div className={formItem}>
      <label className={formLabel} htmlFor={props.name}>
        {props.label}
      </label>
      {props.children}
    </div>
  )
}
